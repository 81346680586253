.text-block {
  padding: 80px 0;
}

.text-block .markdown {
  max-width: 928px;
  margin: 0 auto;
}

.text-block--left .markdown {
  margin-left: 0;
}

.text-block--right .markdown {
  margin-right: 0;
}

@media only screen and (max-width: 899px) {
  .text-block {
    padding: 40px 0;
  }
}
