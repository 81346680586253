.landing-promo .wrap {
  padding: 0;
}

.landing-promo__container {
  display: flex;
  flex-direction: row-reverse;
}

.landing-promo__right-container,
.landing-promo__left-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 50%;
  width: 0;
  padding: 160px 80px;
}

.landing-promo__right-container {
  position: relative;
  background-color: var(--primary);
  color: white;
  z-index: 0;
  overflow: hidden;
}

.landing-promo__right-container::before,
.landing-promo__right-container::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 178%;
  left: 0;
  background-size: 100% 100%;
}

.landing-promo__right-container::before {
  background-image: url('../../assets/arrow.svg');
  transform: translateX(432px);
  z-index: -2;
}

.landing-promo__right-container::after {
  background-image: url('../../assets/arrow-red.svg');
  transform: translateX(0);
  z-index: -1;
}

.landing-promo p {
  line-height: 1.5;
}

.landing-promo__right-container::before,
.landing-promo__right-container::after {
  transition: transform 2.5s linear;
}

.landing-promo:not(.landing-promo--active) .landing-promo__right-container::before {
  transform: translateX(-100%);
}

.landing-promo:not(.landing-promo--active) .landing-promo__right-container::after {
  transform: translateX(-100%) translateX(-432px);
}

@media only screen and (max-width: 1299px) {
  .landing-promo__right-container,
  .landing-promo__left-container {
    padding: 120px 40px;
  }
}

@media only screen and (max-width: 899px) {
  .landing-promo__container {
    flex-direction: column;
  }

  .landing-promo--mobile-swap .landing-promo__container {
    flex-direction: column-reverse;
  }

  .landing-promo__right-container,
  .landing-promo__left-container {
    width: 100%;
  }

  .landing-promo__right-container {
    padding: 119px 24px;
  }

  .landing-promo__left-container {
    padding: 98px 24px;
  }
}
