.intersect {
  opacity: 0;
  transition: 1.5s ease;
}

.intersect--top {
  transform: translate(0, -60px);
}

.intersect--bottom {
  transform: translate(0, 60px);
}

.intersect--left {
  transform: translate(-60px, 0);
}

.intersect--right {
  transform: translate(60px, 0);
}

.intersect--visible {
  opacity: 1;
  transform: translate(0, 0);
}
