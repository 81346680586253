.member-marker {
  display: block;
  position: relative;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background-color: var(--surface100);
  overflow: hidden;
  transition: var(--transition);
  z-index: 1;
}

.hot .member-marker {
  transform: scale(1.2);
  z-index: 2;
}

.member-marker.idx-0 img {
  filter: url('#filter-blue');
}

.member-marker.idx-1 img {
  filter: url('#filter-grey');
}

.member-marker.idx-2 img {
  filter: url('#filter-red');
}

.member-marker.idx-3 img {
  filter: url('#filter-red');
}

.member-marker.idx-4 img {
  filter: url('#filter-blue');
}

.member-marker.idx-5 img {
  filter: url('#filter-grey');
}

.map-marker-container.hot .member-marker img {
  filter: none;
}

@media only screen and (max-width: 1479px) {
  .member-marker.idx-0 img {
    filter: url('#filter-blue');
  }

  .member-marker.idx-1 img {
    filter: url('#filter-grey');
  }

  .member-marker.idx-2 img {
    filter: url('#filter-red');
  }

  .member-marker.idx-3 img {
    filter: url('#filter-blue');
  }

  .member-marker.idx-4 img {
    filter: url('#filter-red');
  }

  .member-marker.idx-5 img {
    filter: url('#filter-grey');
  }
}

@media only screen and (max-width: 999px) {
  .member-marker.idx-0 img {
    filter: url('#filter-blue');
  }

  .member-marker.idx-1 img {
    filter: url('#filter-grey');
  }

  .member-marker.idx-2 img {
    filter: url('#filter-red');
  }

  .member-marker.idx-3 img {
    filter: url('#filter-red');
  }

  .member-marker.idx-4 img {
    filter: url('#filter-blue');
  }

  .member-marker.idx-5 img {
    filter: url('#filter-grey');
  }
}

@media only screen and (max-width: 639px) {
  .member-marker.idx-0 img {
    filter: url('#filter-blue');
  }

  .member-marker.idx-1 img {
    filter: url('#filter-grey');
  }

  .member-marker.idx-2 img {
    filter: url('#filter-red');
  }

  .member-marker.idx-3 img {
    filter: url('#filter-blue');
  }

  .member-marker.idx-4 img {
    filter: url('#filter-red');
  }

  .member-marker.idx-5 img {
    filter: url('#filter-grey');
  }
}
