:root {
  --primary: #218ce7;
  --primary100: #d5dde9;
  --secondary: #ff3b4b;
  --text: #233b4b;
  --surface400: #1c2f3c;
  --surface300: #233c4b;
  --surface200: #e9ebed;
  --surface100: #f2f3f5;
  --success: #7be085;
  --success200: #2bb539;
  --success-text: #1b7324;
  --error: #ffa8a8;
  --error200: #cc0000;
  --error-text: #ad1f1f;
  --warning: #ffcb66;
  --warning200: #ea9a00;
  --warning-text: #ad7200;
  --border300: #cdd1d6;

  --spacing-1: 8px;
  --spacing-2: 16px;
  --spacing-3: 24px;
  --spacing-5: 40px;
  --spacing-10: 80px;
  --spacing-15: 120px;
  --spacing-20: 160px;

  --transition: 300ms ease-out;

  --header-height: 85px;
}

@media only screen and (max-width: 767px) {
  :root {
    --header-height: 73px;
  }
}
