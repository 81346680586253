.article-banner {
  margin-top: 80px;
}

.article-banner .wrap {
  background-color: white;
}

.article-banner__container {
  display: flex;
  align-items: center;
  background-color: var(--surface100);
}

.article-banner__content {
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 24px;
}

.article-banner__desc {
  max-width: 584px;
  font-weight: 500;
}

.article-banner__desc a {
  text-decoration: underline;
}

.article-banner__content ul {
  margin-top: 20px;
}

.article-banner__img-container {
  flex: 0 0 50%;
  margin-left: auto;
}

.article-banner__img {
  position: relative;
  padding-top: 100%;
}

@media only screen and (max-width: 899px) {
  .article-banner__container {
    flex-direction: column;
  }

  .article-banner__desc {
    max-width: none;
  }

  .article-banner__content,
  .article-banner__img-container {
    flex: 0 0 auto;
    width: 100%;
    margin: 0;
  }

  .article-banner__img {
    padding-top: min(100%, 500px);
  }
}
