.promo__container {
  position: relative;
  background-color: var(--text);
  color: white;
  z-index: 0;
  overflow: hidden;
}

.promo__header,
.promo__content,
.promo__footer {
  position: relative;
  display: flex;
  z-index: 2;
}

.promo__header {
  margin-bottom: var(--spacing-5);
}

.promo__footer {
  margin-top: var(--spacing-5);
}

.promo__symbol {
  height: 240px;
  width: 240px;
  margin-right: 24px;
}

.promo__symbol--world {
  width: 195px;
  height: 90px;
}

.promo__label {
  position: relative;
  align-self: flex-end;
  margin: 0 57px 0 auto;
  z-index: 0;
}

.promo__circle,
.promo__arrow {
  position: absolute;
}

.promo__circle {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) translate(7px, 0);
}

.promo__arrow {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) translate(-10px, 36px);
}

.promo__body {
  flex: 0 0 45%;
  margin-right: auto;
}

.promo__title {
  margin-top: var(--spacing-5);
}

.promo__video {
  flex: 0 0 49.3%;
}

.promo__desc {
  margin-top: var(--spacing-2);
}

.promo__info {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-5);
}

.promo__info .icon {
  flex-shrink: 0;
  margin-right: 8px;
}

.promo__background {
  z-index: 1;
  filter: blur(4px);
  top: -4px;
  left: -4px;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  max-width: none;
}

@media only screen and (max-width: 1279px) {
  .promo__symbol:not(.promo__symbol--world) {
    height: 180px;
    width: 180px;
  }

  .promo__content {
    flex-direction: column;
  }

  .promo__title {
    margin-top: 0;
  }

  .promo__info {
    margin-top: var(--spacing-3);
  }

  .promo__video {
    max-width: 600px;
    width: 100%;
    margin-top: var(--spacing-3);
  }

  .promo__label {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .promo__header {
    margin-bottom: var(--spacing-3);
  }

  .promo__footer {
    margin-top: var(--spacing-3);
  }

  .promo__symbol {
    height: 120px;
    width: 120px;
  }

  .promo__symbol--world {
    height: 55px;
    width: 120px;
  }

  .promo__footer {
    flex-direction: column;
    align-items: flex-start;
  }
}
