.landing-banner {
  padding: 96px 0;
  background-color: var(--surface100);
}

.landing-banner .wrap {
  position: relative;
  padding: 0 min(5.6%, 80px);
  z-index: 0;
}

.landing-banner__title {
  display: flex;
  flex-direction: column;
  color: var(--secondary);
  mix-blend-mode: multiply;
}

.landing-banner__title--left,
.landing-banner__title--right {
  width: 48.7%;
}

.landing-banner__title--left {
  margin-left: 86px;
}

.landing-banner__title--right {
  margin: -94px 0 0 auto;
}

.landing-banner__title-logo {
  display: inline-block;
  height: 59px;
  width: auto;
}

.landing-banner__subtitle {
  display: flex;
  align-items: flex-end;
  margin-top: 96px;
}

.landing-banner__subtitle span {
  margin-bottom: -7px;
}

.landing-banner__subtitle-logo {
  height: 38px;
  width: auto;
  margin-right: 18px;
}

.landing-banner__img {
  position: absolute;
  bottom: -96px;
  right: 0;
  width: 800px;
  max-width: 50%;
  z-index: -1;
}

@media only screen and (max-width: 1199px) {
  .landing-banner__title--left,
  .landing-banner__title--right {
    width: 60%;
  }

  .landing-banner__title--left {
    margin-left: 0;
  }

  .landing-banner__title--right {
    margin: 0 0 0 auto;
  }
}

@media only screen and (max-width: 899px) {
  .landing-banner {
    padding: 80px 0 40px;
  }

  .landing-banner .wrap {
    padding: 0 16px;
  }

  .landing-banner__title--left {
    width: 100%;
  }

  .landing-banner__title--right {
    width: 73%;
    margin-top: 10px;
  }

  .landing-banner__title-logo {
    height: 38px;
  }

  .landing-banner__subtitle {
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    margin-top: 112px;
  }

  .landing-banner__subtitle span {
    margin: 5px 0 0;
  }

  .landing-banner__subtitle-logo {
    height: 24px;
    margin-right: 0;
  }

  .landing-banner__img {
    max-width: 69.3%;
    bottom: -40px;
  }
}
