.user-dropdown__avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid var(--border300);
  background-color: white;
  transition: var(--transition);
}

.user-dropdown__avatar:hover {
  background-color: var(--surface100);
}
