.landing-partners .wrap {
  padding: 0;
}

.landing-partners__container {
  display: flex;
  flex-wrap: wrap;
}

.landing-partners__desc-container,
.landing-partners__title-container,
.landing-partners__partner-container {
  width: 50%;
}

.landing-partners__desc-container,
.landing-partners__title-container {
  padding: 128px 4.2%;
}

.landing-partners__desc-container {
  background-color: var(--text);
  color: white;
}

.landing-partners__desc {
  line-height: 1.5;
}

.landing-partners__partner-container {
  display: flex;
  flex-wrap: wrap;
}

.landing-partners__partner {
  position: relative;
  padding-top: 50%;
  width: 50%;
  height: 0;
  overflow: hidden;
}

.landing-partners__logo {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
  transition: var(--transition);
}

.landing-partners__partner-desc {
  position: absolute;
  bottom: 0;
  margin: 0 32px 38px;
  transform: translateY(100%) translateY(38px);
  transition: var(--transition);
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.landing-partners__img-container {
  position: relative;
  width: 50%;
  order: -1;
}

.landing-partners__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
}

.landing-partners__partner:first-child {
  order: -2;
}

div.landing-partners__partner--hover:hover .landing-partners__logo {
  width: 50%;
  height: 50%;
}

.landing-partners__partner--hover:hover .landing-partners__partner-desc {
  transform: translateY(0);
}

.landing-partners__partner-container:nth-last-child(1) > :nth-child(1),
.landing-partners__partner-container:nth-last-child(1) > :nth-child(3),
.landing-partners__partner-container:nth-last-child(2) > :nth-child(1),
.landing-partners__partner-container:nth-last-child(2) > :nth-child(4) {
  background-color: var(--surface100);
}

@media only screen and (max-width: 899px) {
  .landing-partners__desc-container,
  .landing-partners__title-container,
  .landing-partners__partner-container {
    width: 100%;
  }

  .landing-partners__title-container {
    order: 0;
  }

  .landing-partners__partner-container:nth-last-of-type(1) {
    order: 1;
  }

  .landing-partners__desc-container {
    order: 2;
  }

  .landing-partners__partner-container:nth-last-of-type(2) {
    order: 3;
  }

  .landing-partners__desc-container,
  .landing-partners__title-container {
    padding: 80px 24px;
  }

  .landing-partners__info {
    padding: 24px;
  }

  .landing-partners__name {
    width: calc(100% - 48px);
  }

  .landing-partners__info-desc {
    margin-top: 24px;
    font-size: 1.6rem;
    font-weight: 600;
  }

  .landing-partners__close {
    position: absolute;
    top: 24px;
    right: 24px;
    color: var(--primary);
    line-height: 1;
  }

  .landing-partners__close svg {
    transform: rotate(45deg);
  }
}
