.home-banner {
  display: flex;
  background-color: var(--surface100);
}

.home-banner__container {
  position: relative;
}

.home-banner__content {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 960px;
  padding: var(--spacing-20) 0;
  mix-blend-mode: multiply;
  z-index: 1;
}

.home-banner__logo {
  display: block;
  width: 475px;
  height: 259px;
  margin-bottom: -100px;
}

.home-banner__title {
  position: sticky;
  bottom: 40px;
  display: block;
  margin: 294px 0 0 74px;
  color: var(--primary);
}

.home-banner__media-container {
  position: absolute;
  bottom: 0;
  left: 51%;
  width: 844px;
}

.home-banner__media {
  width: 100%;
  display: block;
}

@media only screen and (max-width: 1279px) {
  .home-banner__content {
    padding: var(--spacing-15) 0;
    min-height: 772px;
  }

  .home-banner__logo {
    width: 386px;
    height: 210px;
    margin: 0 0 -50px -43px;
  }

  .home-banner__title {
    margin: 250px 0 0 17px;
    max-width: 50%;
  }

  .home-banner__title br {
    display: none;
  }

  .home-banner__media-container {
    left: auto;
    right: -88px;
    width: 532px;
  }
}

@media only screen and (max-width: 767px) {
  .home-banner__content {
    padding: var(--spacing-10) 0;
    min-height: 451px;
  }

  .home-banner__logo {
    width: 222px;
    height: 121px;
    margin: 0 0 -60px 0;
  }

  .home-banner__title {
    margin: 140px 0 0 0;
    max-width: min(60%, 330px);
  }

  .home-banner__media-container {
    right: -100px;
    width: 331px;
  }
}
