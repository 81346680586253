.cases-showcase__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.cases-showcase__nav {
  margin-left: 40px;
}

.cases-showcase__slider {
  margin-top: 80px;
  overflow: visible;
}

.cases-showcase__item {
  display: flex;
  height: auto;
  min-height: 600px;
  width: 1200px;
  background-color: white;
  color: var(--text);
}

.cases-showcase__img-container {
  position: relative;
  flex: 0 0 400px;
  width: 0;
  margin-right: 40px;
}

.cases-showcase__content {
  flex: 1 0 auto;
  align-items: flex-start;
  width: 0;
}

.cases-showcase__summary {
  -webkit-line-clamp: 8;
}

.cases-showcase__tags {
  display: flex;
  flex-wrap: wrap;
  margin: 20px -4px -4px;
}

.cases-showcase__tags .tag {
  margin: 4px;
}

.cases-showcase__summary {
  margin: 40px 0;
}

.cases-showcase__cta {
  margin-top: auto;
}

@media only screen and (max-width: 1279px) {
  .cases-showcase__slider {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .cases-showcase__header {
    flex-direction: column;
    align-items: flex-start;
  }

  .cases-showcase__nav {
    margin: 24px 0 0 0;
  }

  .cases-showcase__item {
    width: 288px;
    flex-direction: column;
  }

  .cases-showcase__img-container {
    width: 100%;
    flex: 0 0 auto;
    margin: 0 0 24px 0;
    padding-top: 100%;
  }

  .cases-showcase__content {
    width: 100%;
  }

  .cases-showcase__tags {
    margin-top: 16px;
  }

  .cases-showcase__summary {
    margin: 24px 0;
    -webkit-line-clamp: 10;
  }
}
