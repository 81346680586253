.message {
  display: flex;
  align-items: center;
  margin-top: 24px;
  padding: 16px;
  width: 100%;
}

.message svg {
  flex: 0 0 auto;
  align-self: flex-start;
  width: 40px;
  height: 40px;
  margin-right: 8px;
  padding: 8px;
  border-radius: 50%;
}

.message--success {
  background-color: var(--success);
  color: var(--success-text);
}

.message--success svg {
  background-color: var(--success200);
  color: var(--success);
}

.message--warning {
  background-color: var(--warning);
  color: var(--warning-text);
}

.message--warning svg {
  background-color: var(--warning200);
  color: var(--warning);
}

.message--error {
  background-color: var(--error);
  color: var(--error-text);
}

.message--error svg {
  background-color: var(--error200);
  color: var(--error);
  transform: rotate(45deg);
}
