.modal .hs-form {
  padding: 60px 40px;
}

.hs-form-field,
.hs-form-checkbox {
  margin-bottom: 16px;
}

.hs-form-field label {
  font-size: 2rem;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: 8px;
}

.hs-form .input {
  margin: 0;
}

.hs-input {
  width: 100%;
  padding: 15px 12px;
  font-size: 2rem;
  line-height: 1.5;
  background-color: white;
  color: var(--text);
  font-family: 'Albert', sans-serif;
  border: 1px solid var(--primary100);
  border-radius: 2px;
}

.hs-input:focus-visible {
  border: 2px solid var(--primary);
  padding: 14px 11px;
}

.hs-error-msgs .hs-error-msg {
  font-size: 1.8rem;
  color: var(--error-text);
}

.hs-input.error {
  border-color: var(--error200);
  border-width: 2px;
  padding: 14px 11px;
}

.hs-form-checkbox-display,
.hs-form-booleancheckbox-display {
  display: flex;
  align-items: flex-start;
}

.hs-form-checkbox .hs-input,
.hs-form-booleancheckbox-display .hs-input {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin: 6px 12px 0 0;
}

.hs-form-checkbox .hs-input:focus-visible + span,
.hs-form-booleancheckbox-display .hs-input:focus-visible + span {
  outline: 2px solid var(--text);
}

.multi-container {
  margin: 16px 8px;
}

.hs-form .inputs-list .hs-form-booleancheckbox-display span {
  margin-left: 0;
}

.hs-richtext {
  margin-bottom: 16px;
}

.hs-button {
  display: inline-flex;
  align-items: center;
  padding: 15px 24px;
  font-size: 2rem;
  line-height: 1.5;
  letter-spacing: -0.05em;
  font-weight: 600;
  border-radius: 2px;
  border: none;
  outline-offset: 3px;
  transition: border-radius var(--transition);
  color: white;
  background: var(--primary);
  margin-top: 20px;
}

.hs-button:hover,
.hs-button:focus-visible {
  border-radius: 60px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--primary);
}

.submitted-message {
  padding: 80px 40px;
  font-size: 3rem;
  font-weight: 600;
}

.hs-richtext h2 {
  font-size: 4rem;
  font-weight: 600;
  margin-bottom: 40px;
}

@media only screen and (max-width: 899px) {
  .modal .hs-form {
    padding: 40px 16px 16px;
  }

  .submitted-message {
    padding: 40px 20px;
    font-size: 2rem;
  }

  .hs-richtext h2 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
}
