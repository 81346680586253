.community-item {
  flex: 0 0 calc((100% - 2 * var(--spacing-5)) / 3);
  width: 0;
}

.community-item__container {
  width: 100%;
  height: 100%;
  text-align: left;
}

.community-item__container.active .community-item__name {
  text-decoration: underline;
}

.community-item__container.active .community-item__media img {
  filter: none;
}

.community-item__name {
  text-overflow: ellipsis;
  overflow: hidden;
}

.community-item__media {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.community-item__exp {
  opacity: 0.8;
}

.community-item:nth-child(6n + 1) img {
  filter: url('#filter-blue');
}

.community-item:nth-child(6n + 2) img {
  filter: url('#filter-grey');
}

.community-item:nth-child(6n + 3) img {
  filter: url('#filter-red');
}

.community-item:nth-child(6n + 4) img {
  filter: url('#filter-red');
}

.community-item:nth-child(6n + 5) img {
  filter: url('#filter-blue');
}

.community-item:nth-child(6n + 6) img {
  filter: url('#filter-grey');
}

.community-item--loader {
  height: 300px;
}

@media only screen and (max-width: 1479px) {
  .community-item {
    flex: 0 0 calc((100% - var(--spacing-5)) / 2);
  }

  .community-item:nth-child(6n + 1) img {
    filter: url('#filter-blue');
  }

  .community-item:nth-child(6n + 2) img {
    filter: url('#filter-grey');
  }

  .community-item:nth-child(6n + 3) img {
    filter: url('#filter-red');
  }

  .community-item:nth-child(6n + 4) img {
    filter: url('#filter-blue');
  }

  .community-item:nth-child(6n + 5) img {
    filter: url('#filter-red');
  }

  .community-item:nth-child(6n + 6) img {
    filter: url('#filter-grey');
  }
}

@media only screen and (max-width: 999px) {
  .community-item {
    flex: 0 0 calc((100% - 2 * var(--spacing-5)) / 3);
  }

  .community-item:nth-child(6n + 1) img {
    filter: url('#filter-blue');
  }

  .community-item:nth-child(6n + 2) img {
    filter: url('#filter-grey');
  }

  .community-item:nth-child(6n + 3) img {
    filter: url('#filter-red');
  }

  .community-item:nth-child(6n + 4) img {
    filter: url('#filter-red');
  }

  .community-item:nth-child(6n + 5) img {
    filter: url('#filter-blue');
  }

  .community-item:nth-child(6n + 6) img {
    filter: url('#filter-grey');
  }
}

@media only screen and (max-width: 767px) {
  .community-item {
    flex: 0 0 calc((100% - 2 * var(--spacing-3)) / 3);
  }
}

@media only screen and (max-width: 639px) {
  .community-item {
    flex: 0 0 calc((100% - var(--spacing-3)) / 2);
  }

  .community-item:nth-child(6n + 1) img {
    filter: url('#filter-blue');
  }

  .community-item:nth-child(6n + 2) img {
    filter: url('#filter-grey');
  }

  .community-item:nth-child(6n + 3) img {
    filter: url('#filter-red');
  }

  .community-item:nth-child(6n + 4) img {
    filter: url('#filter-blue');
  }

  .community-item:nth-child(6n + 5) img {
    filter: url('#filter-red');
  }

  .community-item:nth-child(6n + 6) img {
    filter: url('#filter-grey');
  }
}
