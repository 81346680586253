.custom-dropdown {
  position: relative;
}

.custom-dropdown__menu {
  background: white;
  color: var(--text);
  text-align: left;
}

.custom-dropdown__menu {
  position: absolute;
  top: 100%;
  width: max-content;
  max-height: 230px;
  opacity: 0;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
  visibility: hidden;
  transition: transform var(--transition), opacity var(--transition);
  transform: translateY(6px);
  list-style: none;
  overflow: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 2;
}

.custom-dropdown__menu::-webkit-scrollbar {
  display: none;
}

.custom-dropdown__menu--visible {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.custom-dropdown__item {
  display: block;
  padding: 11px 16px;
  cursor: pointer;
}

.custom-dropdown__item--active {
  text-decoration: underline;
}

.custom-dropdown__item:hover {
  background-color: var(--surface100);
}
