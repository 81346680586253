.articles-lister {
  margin: 80px 0 160px;
}

.articles-lister__list {
  display: flex;
  flex-wrap: wrap;
}

.articles-lister__item {
  flex: 0 0 calc((100% - 3 * var(--spacing-5)) / 4);
  width: 0;
}

.articles-lister__content {
  gap: var(--spacing-1);
}

.articles-lister__link {
  position: relative;
  height: 100%;
  outline-offset: 10px;
  gap: var(--spacing-3);
}

.articles-lister__link:focus-visible .articles-lister__title,
.articles-lister__link:hover .articles-lister__title {
  text-decoration: underline;
}

.articles-lister__img {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.articles-lister__aspect-container {
  position: relative;
  padding-top: 100%;
}

.articles-lister__title {
  width: 100%;
}

.articles-lister__message {
  margin: 80px 0 120px;
}

.articles-lister__placeholder {
  width: 100%;
  padding-top: 200%;
}

@media only screen and (max-width: 1279px) {
  .articles-lister {
    margin: 60px 0;
  }

  .articles-lister__item {
    flex: 0 0 calc((100% - 2 * var(--spacing-5)) / 3);
  }
}

@media only screen and (max-width: 767px) {
  .articles-lister__item {
    flex: 0 0 calc((100% - var(--spacing-3)) / 2);
  }

  .articles-lister__link {
    gap: var(--spacing-2);
  }
}
