.scrollspy {
  position: sticky;
  top: calc(var(--header-height) + 10px);
  flex: 0 0 auto;
  max-width: 200px;
  margin-left: 40px;
}

.scrollspy__container {
  display: flex;
  flex-direction: column;
}

.scrollspy__btn {
  font-size: 2rem;
  font-weight: 600;
  padding: 13px 0;
  border-bottom: 2px solid transparent;
}

.scrollspy__btn.active {
  border-color: var(--text);
}

.scrollspy__btn:hover:not(.active) {
  border-color: var(--border300);
}

@media only screen and (max-width: 1279px) {
  .scrollspy {
    top: var(--header-height);
    max-width: unset;
    width: calc(100% + 48px);
    z-index: 1;
    margin: 0 -24px;
  }

  .scrollspy__container {
    flex-direction: row;
    overflow-x: auto;
    padding: 0 24px;
  }

  .scrollspy::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 120vw;
    transform: translateX(-50%);
    background-color: rgba(255, 255, 255, 0.92);
    backdrop-filter: blur(4px);
    z-index: -1;
  }

  .scrollspy__item {
    flex-shrink: 0;
  }
}
