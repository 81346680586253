.legal {
  padding: 160px 0 80px;
}

.legal__header {
  display: flex;
  justify-content: flex-start;
}

.legal__header .toggle-select {
  margin: 0;
}

.legal ol,
.legal ul {
  margin: 20px 1.5rem;
  padding: 0;
}

.legal ol li {
  list-style: decimal;
  margin: 30px 0;
  padding-left: 1rem;
  list-style-position: outside;
}

.legal ul li {
  list-style: disc;
  margin: 12px 0;
  list-style-position: inside;
}

.legal li ol,
.legal li ul {
  margin: 20px 0;
}

.legal li li {
  margin: 12px 0;
}

.legal .markdown h2 {
  margin: 52px 0 12px;
}

.legal .markdown p {
  margin: 28px 0;
}

.legal h1,
.legal h2 {
  position: relative;
  display: inline-block;
}

@media only screen and (max-width: 899px) {
  .legal {
    padding: 112px 0 80px;
  }

  .legal .markdown h2 {
    margin: 12px 0 0;
  }
}
