.community-map {
  width: 100%;
  height: calc((100 * var(--vh)) - var(--header-height) - 2 * var(--spacing-10));
}

.map-cluster {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 0;
  color: white;
  font-size: 2rem;
  border-radius: 50%;
  overflow: hidden;
}

.map-marker-container {
  transform: translate(-50%, -50%);
}

.map-marker-container:hover {
  z-index: 3;
}

.map-marker-container:nth-of-type(3n + 1) .map-cluster {
  background-color: var(--secondary);
}

.map-marker-container:nth-of-type(3n + 2) .map-cluster {
  background-color: var(--primary);
}

.map-marker-container:nth-of-type(3n + 3) .map-cluster {
  background-color: var(--text);
}

.marker-pane {
  filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.25));
}
