.pick-field__label {
  display: block;
  margin-bottom: 8px;
}

.pick-field__container {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
  background-color: white;
}

.pick-field__selected {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 7px 8px;
  background-color: var(--surface100);
  font-weight: 500;
}

.pick-field__selected:hover {
  background-color: var(--primary100);
}

.pick-field__selected svg {
  opacity: 0.5;
}

.pick-field__container .input {
  flex-grow: 1;
  width: auto;
  margin: 0;
}

.pick-field__container .input__field {
  padding: 7px 8px;
  border: none;
  font-size: 1.8rem;
  font-weight: 500;
}

.pick-field__option {
  display: block;
  width: 100%;
  padding: 11px 16px;
  text-align: left;
}

.pick-field__option:hover {
  background-color: var(--surface100);
}

.pick-field__dropdown {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
  max-height: 220px;
  opacity: 0;
  background: white;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
  visibility: hidden;
  transition: var(--transition);
  transform: translateY(6px);
  list-style: none;
  overflow: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 2;
}

.pick-field__dropdown::-webkit-scrollbar {
  display: none;
}

.pick-field__dropdown--visible {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}
