.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 14px 0;
  z-index: 100;
  transition: var(--transition);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0);
  transition-property: background, box-shadow;
}

.header.scrolled {
  background: rgba(255, 255, 255, 0.92);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);
}

.header__nav {
  display: flex;
  align-items: center;
}

.header__logo {
  display: flex;
  align-items: center;
  gap: 6px;
}

.header__logo-mask {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 0;
  opacity: 0;
  transition: var(--transition);
  overflow: hidden;
}

.header__logo svg {
  flex-shrink: 0;
}

.header__logo-symbol,
.header__logo-letter {
  height: 36px;
  width: auto;
}

.header__logo-symbol {
  transition: var(--transition);
}

.header__logo:focus-visible .header__logo-mask,
.header__logo:hover .header__logo-mask {
  width: 100%;
  opacity: 1;
}

.header__logo:focus-visible .header__logo-symbol,
.header__logo:hover .header__logo-symbol {
  transform: rotate(135deg);
}

.header__nav-list {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.header__nav-item {
  margin-left: 40px;
}

.header__nav-link {
  position: relative;
  display: block;
  padding: 15px 0;
  font-size: 1.8rem;
  line-height: 1.5;
  font-weight: 600;
  color: var(--text);
  letter-spacing: -0.9px;
}

.header__nav-link::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: var(--text);
  transition: var(--transition);
  transform: translateX(-50%);
}

.header__nav-link:hover::after,
.header__nav-link:focus-visible::after,
.header__nav-link.active::after {
  width: 100%;
}

.header__burger {
  margin-left: auto;
}

.header__close {
  padding: 5px;
  margin-left: auto;
}

.header__mobile-nav {
  display: flex;
  flex-direction: column;
  padding: 24px;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .header {
    padding: 8px 0;
  }

  .header > .wrap {
    padding: 0 16px;
  }

  .header__nav-list {
    flex-direction: column;
    gap: 36px;
    margin: 80px 0;
  }

  .header__nav-item {
    margin: 0;
    font-size: 1.6rem;
  }

  .header__logo--mobile {
    position: fixed;
    top: 24px;
    left: 16px;
  }

  .header__logo-symbol {
    height: 24px;
  }

  .user-dropdown__avatar {
    margin-top: 16px;
  }

  .header__nav-link {
    font-size: 2rem;
  }
}
