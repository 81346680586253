.chat {
  margin-top: var(--spacing-5);
}

.chat__container {
  padding: 24px;
  color: var(--text);
  background-color: white;
}

.chat__content {
  position: relative;
  display: flex;
  gap: 24px;
  margin-top: 24px;
}

.chat__threads {
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
  position: relative;
  flex: 0 0 0;
  background-color: white;
  border-right: 1px solid var(--surface200);
  overflow-x: hidden;
  transition: var(--transition);
  z-index: 1;
}

.chat__content.open .chat__threads {
  flex: 0 0 210px;
}

.chat__thread-toggle {
  position: absolute;
  top: 50%;
  left: -24px;
  background-color: white;
  padding: 10px;
  border: 1px solid var(--surface200);
  border-radius: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  transition: left var(--transition);
  z-index: 1;
}

.chat__content.open .chat__chat .chat__thread-toggle {
  transform: translate(-50%, -50%) rotate(90deg);
}

.chat__thread-container {
  position: relative;
  width: 175px;
}

.chat__thread {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px;
  width: 175px;
  font-size: 1.6rem;
  border: 1px solid var(--surface200);
  border-radius: 8px;
  text-align: left;
}

.chat__thread.active {
  border-color: var(--surface400);
}

.chat__thread--new {
  font-size: 1.6rem;
}

.chat__thread-delete {
  position: absolute;
  top: 50%;
  right: 0;
  color: var(--error-text);
  padding: 5px;
  background-color: white;
  border: 1px solid var(--surface200);
  border-radius: 50%;
  transform: translate(50%, -50%);
  z-index: 1;
}

.chat__thread-delete:hover {
  border-color: var(--surface400);
}

.chat__chat {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  transition: var(--transition);
}

.chat__form {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
}

.chat__form .btn {
  padding: 15px 24px;
}

.chat__messages-container {
  position: relative;
  width: 100%;
}

.chat__messages {
  display: flex;
  flex-direction: column-reverse;
  gap: 24px;
  align-items: flex-start;
  width: 100%;
  padding: 30px 10px 10px;
  border: 1px solid var(--surface200);
  height: 300px;
  overflow-y: auto;
}

.chat__messages.loading {
  background-color: var(--surface100);
  opacity: 0.5;
}

.chat__messages.loading::after {
  content: 'Loading...';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.chat__message-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 60px 0 0;
}

.chat__message-container--user {
  align-self: flex-end;
  align-items: flex-end;
  margin: 0 0 0 60px;
}

.chat__message {
  padding: 5px 10px;
  background-color: var(--primary100);
  margin-bottom: 8px;
}

.chat__message-container--user .chat__message {
  background-color: var(--surface200);
}

.chat__message-date {
  opacity: 0.5;
  font-size: 1.4rem;
}

@media only screen and (max-width: 767px) {
  .chat__chat {
    gap: 16px;
  }

  .chat__threads {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    border: 0;
  }

  .chat__content.open .chat__threads {
    width: 210px;
    border-right: 1px solid var(--surface200);
  }

  .chat__thread-toggle {
    left: 0;
  }

  .chat__content.open .chat__thread-toggle {
    left: 210px;
  }

  .chat__form {
    flex-direction: column;
    gap: 16px;
  }

  .chat__form .btn {
    width: 100%;
    justify-content: center;
  }
}
