.introduction__container {
  display: flex;
}

.introduction__left,
.introduction__right {
  flex: 0 0 calc((100% - 80px) / 2);
}

.introduction__right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media only screen and (max-width: 1279px) {
  .introduction__container {
    flex-direction: column;
  }

  .introduction__left,
  .introduction__right {
    flex: 0 0 auto;
  }

  .introduction__title br {
    display: none;
  }
}
