.article__header-info,
.article__header-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.article__header-info {
  justify-content: space-between;
}

.article__body {
  margin: 0 auto;
  font-weight: 500;
}

.article__body.markdown h2,
.article__body.markdown h3,
.article__body.markdown h4,
.article__body.markdown h6,
.article__body.markdown h5,
.article__body.markdown ol,
.article__body.markdown ul,
.article__body.markdown a,
.article__body.markdown p {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.article__body h2:not(:first-child) {
  margin-top: 80px;
}

.article__body h3:not(:first-child) {
  margin-top: 60px;
}

.article__body img,
.article__body p:has(img),
.article__body.markdown .video {
  display: block;
  margin: 80px auto;
  max-width: min(880px, 100%);
}

.article__body .vjs-poster img {
  margin: 0;
  max-width: none;
}

.article__img {
  width: 100%;
  display: block;
}

.bctt-ctt-btn {
  display: none;
}

.article__body blockquote,
.bctt-click-to-tweet .bctt-ctt-text a {
  display: block;
  padding: var(--spacing-10);
  color: white;
  font-size: 4rem;
  text-decoration: none;
  background-color: var(--primary);
}

.article .article__body blockquote p {
  max-width: none;
}

.article .article__body blockquote a {
  color: white;
}

@media only screen and (max-width: 899px) {
  .page-title__info {
    margin-top: 24px;
  }

  .article__header-info {
    flex-direction: column;
  }

  .article__body img,
  .article__body p:has(img),
  .article__body.markdown .video {
    margin: 40px auto;
  }

  .article__content {
    margin-bottom: 80px;
  }

  .article__body blockquote,
  .bctt-click-to-tweet .bctt-ctt-text a {
    padding: var(--spacing-5);
    font-size: 2.4rem;
  }
}
