.article-featured__container {
  display: flex;
  transform: scale(0.93) translateY(160px);
  transition: var(--transition);
}

.article-featured__container.show {
  transform: none;
}

.article-featured__content-container {
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.article-featured__content {
  align-items: flex-start;
}

.article-featured__img {
  flex: 0 0 50%;
  position: relative;
  padding-top: 50%;
  overflow: hidden;
}

.article-featured__img img {
  transform: scale(1.07);
  transition: var(--transition);
}

.article-featured__container.show .article-featured__img img {
  transform: none;
}

.article-featured--blue .article-featured__content-container {
  background-color: var(--primary);
  color: white;
}

.article-featured--grey .article-featured__content-container {
  background-color: var(--surface100);
}

.article-featured--white .article-featured__content-container {
  background-color: white;
}

.article-featured__summary a {
  text-decoration: underline;
}

.article-featured--blue .article-featured__summary a:hover {
  color: var(--text);
}

.article-featured--grey .article-featured__summary a:hover,
.article-featured--white .article-featured__summary a:hover {
  color: var(--primary);
}

@media only screen and (max-width: 1279px) {
  .article-featured__container {
    flex-direction: column-reverse;
  }

  .article-featured__content-container {
    padding: 40px 24px;
  }

  .article-featured__content {
    max-width: none;
  }

  .article-featured__summary,
  .article-featured__cta {
    margin-top: 24px;
  }

  .article-featured__img {
    flex: 0 0 auto;
    padding-top: min(100%, 400px);
  }

  .article-featured__authors,
  .article-featured__info {
    margin-top: 8px;
  }
}
