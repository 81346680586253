.invite-form {
  width: 100%;
}

.invite-form__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: var(--spacing-20);
  background-color: white;
}

.invite-form__card {
  display: block;
  position: relative;
  width: 300px;
  height: 300px;
  margin: 20px 0;
}

.invite-form__card-face {
  position: absolute;
  width: 100%;
  height: 100%;
}

.invite-form__img {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  border: 2px dashed var(--primary);
}

.invite-form__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
}

.invite-form__image_upload_input {
  display: flex;
  gap: 20px;
}

.invite-form__image_removal_button {
  color: var(--secondary);
}

.invite-form__login {
  margin-top: 40px;
}

@media only screen and (max-width: 1279px) {
  .invite-form__container {
    margin-bottom: var(--spacing-15);
  }
}

@media only screen and (max-width: 767px) {
  .invite-form__container {
    margin-bottom: var(--spacing-10);
  }
}
