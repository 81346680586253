.user-menu {
  margin: var(--spacing-20) 0;
}

.user-menu__title {
  margin-bottom: 40px;
}

.user-menu__container {
  padding: 16px 24px;
  background-color: var(--surface100);
}

.user-menu__button {
  width: 100%;
}

.user-menu__button:not(:last-of-type) {
  border-bottom: 1px solid var(--border300);
}

.user-menu__underline {
  border-bottom: 2px solid var(--surface100);
}

@media only screen and (max-width: 1279px) {
  .user-menu {
    margin: var(--spacing-15) 0;
  }
}

@media only screen and (max-width: 767px) {
  .user-menu {
    margin: var(--spacing-10) 0;
  }
}
