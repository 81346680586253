.pager {
  display: flex;
  margin: 40px -16px;
}

.pager__container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0 30px;
}

.pager__list {
  display: flex;
  align-items: center;
}

.pager__item {
  margin: 4px;
}

.pager__btn {
  padding: 4px;
  min-width: 24px;
}

.pager__btn--current {
  font-size: 2.2rem;
  font-weight: 600;
}

.pager__arrow {
  position: absolute;
  top: 50%;
  padding: 4px;
}

.pager__btn:hover,
.pager__arrow:hover {
  color: var(--primary);
}

.pager__arrow--prev {
  left: 0;
  transform: translateY(-50%) rotate(90deg);
}

.pager__arrow--next {
  right: 0;
  transform: translateY(-50%) rotate(-90deg);
}

.pager__dots {
  margin: 0 8px;
  font-size: 2rem;
}
