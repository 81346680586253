.login-form {
  margin: var(--spacing-20) 0;
}

.login-form__title {
  margin-bottom: 40px;
}

@media only screen and (max-width: 1279px) {
  .login-form {
    margin: var(--spacing-15) 0;
  }
}

@media only screen and (max-width: 767px) {
  .login-form {
    margin: var(--spacing-10) 0;
  }
}
