.not-found {
  padding: 180px 0;
  text-align: center;
}

.not-found__title {
  margin-bottom: 40px;
}

@media only screen and (max-width: 899px) {
  .not-found {
    padding: 80px 0;
  }
}
