.marker-group {
  display: flex;
  align-items: center;
  padding: 8px 0;
  overflow: hidden;
  background: var(--primary100);
  border-radius: 48px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
}

.marker-group .member-marker {
  flex: 0 0 auto;
  transform: none;
}

.marker-group .member-marker:hover .member-marker__img {
  filter: none;
}

.marker-group__slider {
  width: 220px;
}

.marker-group__slider.two {
  width: 163px;
  padding: 0 10px;
}

.marker-group__slider.three {
  width: 239px;
  padding: 0 10px;
}

.marker-group__slider .swiper-wrapper {
  margin-right: -16px;
}

.marker-group__slide {
  width: 68px;
}

.map-marker-container.group-container {
  transform: translate(-50%, 34px);
  z-index: 2;
}

.marker-group__arrow {
  flex: 0 0 56px;
  width: 56px;
  padding: 18px;
  color: var(--primary);
}

.marker-group__arrow:disabled {
  opacity: 0.5;
}
