.landing-people .wrap {
  padding: 0;
}

.landing-people__container {
  display: flex;
  flex-wrap: wrap;
}

.landing-people__double {
  display: flex;
  align-items: center;
  flex: 0 0 50%;
}

.landing-people__single {
  position: relative;
  flex: 0 0 25%;
  min-height: calc(var(--ew) * 25);
  padding: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 0;
}

.landing-people h3 {
  overflow: hidden;
  text-overflow: ellipsis;
}

.landing-people__person {
  display: flex;
  align-items: flex-end;
}

.landing-people__title {
  padding: 3.5% 8.4%;
}

.landing-people__img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  bottom: 0;
  left: 0;
  transition: var(--transition);
  z-index: -1;
}

.landing-people__info {
  opacity: 0;
  transition: var(--transition);
  font-weight: 600;
}

.landing-people__stats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.landing-people__stat {
  margin-bottom: 16px;
}

.landing-people__name {
  margin-bottom: 8px;
}

.landing-people__role {
  opacity: 0.8;
}

.landing-people__desc {
  margin-top: 24px;
}

.landing-people__link {
  display: inline-flex;
  align-items: center;
  margin-top: 24px;
  padding: 17px 0;
}

.landing-people__link:hover,
.landing-people__link:focus-visible {
  text-decoration: underline;
}

.landing-people__link svg {
  margin-right: 8px;
}

.landing-people__single.light-blue {
  background-color: var(--primary);
  color: white;
}

.landing-people__single.dark-blue {
  background-color: var(--text);
  color: white;
}

.landing-people__single.red {
  background-color: var(--secondary);
  color: white;
}

.landing-people__single.light-blue *,
.landing-people__single.dark-blue *,
.landing-people__single.red * {
  outline-color: white;
}

.landing-people__single.grey {
  background-color: var(--surface100);
}

.landing-people__person:focus-within .landing-people__info,
.landing-people__person:hover .landing-people__info {
  opacity: 1;
}

.landing-people__person:focus-within .landing-people__img,
.landing-people__person:hover .landing-people__img {
  opacity: 0.25;
  filter: blur(8px);
  transform: scale(1.037);
}

.landing-people__close {
  position: absolute;
  top: 24px;
  right: 24px;
  color: var(--primary);
  line-height: 1;
}

.landing-people__close svg {
  transform: rotate(45deg);
}

@media only screen and (max-width: 1200px) {
  .landing-people__double {
    flex: 0 0 calc(100% * 2 / 3);
  }

  .landing-people__single {
    flex: 0 0 calc(100% / 3);
    min-height: calc(var(--ew) * 100 / 3);
  }
}

@media only screen and (max-width: 899px) {
  .landing-people__double {
    flex: 0 0 100%;
  }

  .landing-people__person {
    padding-top: 50%;
    min-height: auto;
  }

  .landing-people__single {
    flex: 0 0 50%;
    padding: 20px;
    min-height: calc(var(--vw) * 50);
  }

  .landing-people__title {
    padding: 40px 24px;
  }

  .landing-people__info {
    position: relative;
    opacity: 1;
    padding: 24px;
  }

  .landing-people__person:focus-within .landing-people__img,
  .landing-people__person:hover .landing-people__img {
    opacity: 1;
    filter: blur(0);
    transform: scale(1);
  }

  .landing-people__name {
    width: calc(100% - 32px);
  }

  .landing-people__role {
    font-size: 1.4rem;
  }

  .landing-people__desc {
    font-size: 1.6rem;
  }
}
