.image-field__btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 130px;
  border: 3px solid white;
  background-color: white;
  font-size: 3rem;
}

.image-field__btn:hover {
  border-color: var(--primary100);
}

.image-field__img {
  user-select: none;
  pointer-events: none;
}
