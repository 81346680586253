.forgot-password {
  margin: var(--spacing-20) 0;
}

@media only screen and (max-width: 1279px) {
  .forgot-password {
    margin: var(--spacing-15) 0;
  }
}

@media only screen and (max-width: 767px) {
  .forgot-password {
    margin: var(--spacing-10) 0;
  }
}
