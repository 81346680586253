.footer {
  padding: 160px 0 80px;
  margin-top: auto;
  font-weight: 600;
  background-color: var(--surface300);
  color: white;
}

.footer__top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer__right {
  flex: 0 0 368px;
}

.footer .hs-form-field label {
  font-size: 1.8rem;
  margin: 0;
}

.footer__logo {
  width: 475px;
  height: auto;
}

.footer__corp {
  margin-left: auto;
}

.footer__corp-icon {
  width: 56px;
  height: auto;
}

.footer__info {
  margin-left: 74px;
  text-decoration: underline;
}

.footer .newsletter-inline {
  margin-top: auto;
}

.footer__bottom {
  margin-left: 74px;
}

.footer__bottom-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer__social,
.footer__bottom-list {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
}

.footer__bottom-list {
  text-decoration: underline;
}

.footer__social-link,
.footer__address-link {
  display: block;
}

.footer .footer__container *:focus-visible {
  outline: 1px solid white;
}

@media only screen and (max-width: 1279px) {
  .footer {
    padding: 120px 0 80px;
  }

  .footer__top,
  .footer__bottom-nav {
    flex-direction: column;
  }

  .footer__right {
    flex: 0 0 auto;
  }

  .footer__corp {
    margin: 0;
  }

  .footer__right {
    margin: 0 74px;
  }

  .footer__bottom-nav {
    align-items: flex-start;
  }
}

@media only screen and (max-width: 767px) {
  .footer {
    padding: 80px 0;
  }

  .footer__bottom-list {
    flex-direction: column;
  }

  .footer__logo {
    width: 267px;
  }

  .footer__info,
  .footer__right {
    margin: 0 42px;
  }

  .footer__bottom {
    margin: 0 36px;
  }
}
