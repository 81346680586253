.page-title {
  margin: var(--spacing-20) 0 var(--spacing-10);
}

.page-title__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
}

.page-title__back {
  display: inline-flex;
  align-items: center;
  padding: 15px 0;
  margin-bottom: 40px;
}

.page-title__back-icon {
  margin-right: 8px;
  transform: rotate(90deg);
}

.page-title__info {
  margin-top: 40px;
}

@media only screen and (max-width: 1279px) {
  .page-title {
    margin: var(--spacing-15) 0 var(--spacing-10);
  }

  .page-title__back {
    margin-bottom: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .page-title {
    margin: var(--spacing-10) 0 var(--spacing-5);
  }

  .page-title__back {
    margin-bottom: 22px;
  }
}
