.markdown__body.markdown h2,
.markdown__body.markdown h3,
.markdown__body.markdown h4,
.markdown__body.markdown h6,
.markdown__body.markdown h5,
.markdown__body.markdown ol,
.markdown__body.markdown ul,
.markdown__body.markdown a,
.markdown__body.markdown p {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.markdown__body h2:not(:first-child) {
  margin-top: 80px;
}

.markdown__body h3:not(:first-child) {
  margin-top: 60px;
}

.markdown__body img,
.markdown__body p:has(img),
.markdown__body.markdown .video {
  display: block;
  margin: 80px auto;
  max-width: min(880px, 100%);
}

.markdown__body .vjs-poster img {
  margin: 0;
  max-width: none;
}

.markdown__body blockquote {
  display: block;
  padding: var(--spacing-10);
  color: white;
  font-size: 4rem;
  text-decoration: none;
  background-color: var(--primary);
}

.markdown .markdown__body blockquote p {
  max-width: none;
}

.markdown .markdown__body blockquote a {
  color: white;
}

@media only screen and (max-width: 899px) {
  .markdown__body img,
  .markdown__body p:has(img),
  .markdown__body.markdown .video {
    margin: 40px auto;
  }

  .markdown__body blockquote {
    padding: var(--spacing-5);
    font-size: 2.4rem;
  }
}
