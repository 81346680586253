.video__container .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  max-height: 16em;
}

.video__container .video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video__container .video-js ul li {
  margin: 0;
  list-style: none;
}
