.people {
  margin-bottom: 160px;
}

.people__container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-weight: 500;
}

.people__content {
  flex: 0 2 720px;
}

.people__details {
  flex: 0 1 608px;
  background-color: var(--primary);
  color: white;
}

.people__img-container {
  position: relative;
  padding-top: 100%;
}

.people__item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.people__item span {
  flex: 0 0 calc((100% - var(--spacing-2)) / 2);
}

.people__item-label {
  opacity: 0.8;
}

.people__link {
  text-decoration: underline;
  text-align: left;
}

.people__item-social {
  justify-content: center;
}

.people__questions {
  background-color: var(--surface100);
}

@media only screen and (max-width: 1279px) {
  .people {
    margin-bottom: 120px;
  }

  .people__container {
    flex-direction: column;
  }

  .people__details {
    max-width: 608px;
  }
}

@media only screen and (max-width: 767px) {
  .people {
    margin-bottom: 80px;
  }

  .people__details {
    max-width: none;
    width: 100%;
  }

  .people__item .people__item-label {
    flex: 0 0 calc((100% - var(--spacing-1)) / 2.5);
  }
  .people__item .body-large {
    flex: 0 0 calc((100% - var(--spacing-1)) / 1.7);
  }
}
