.section-bg--white {
  padding: 0;
  margin: var(--spacing-20) 0;
}

.gpt__container {
  display: flex;
}

.gpt__left,
.gpt__right {
  flex: 0 0 calc((100% - 80px) / 2);
}

.gpt__right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.gpt__btn-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

@media only screen and (max-width: 1279px) {
  .section-bg--white {
    margin: var(--spacing-15) 0;
  }

  .gpt__container {
    flex-direction: column;
  }

  .gpt__left,
  .gpt__right {
    flex: 0 0 auto;
  }

  .gpt__title br {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .section-bg--white {
    margin: var(--spacing-10) 0;
  }
}
