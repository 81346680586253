.partners-slider {
  overflow: hidden;
}

.partners-slider__title {
  text-align: center;
}

.partners-slider__lane {
  display: flex;
  gap: 16px;
}

.partners-slider__lane:not(:last-of-type) {
  margin-bottom: 16px;
}

.partners-slider__item-container {
  display: flex;
  flex: 0 0 100%;
  gap: 16px;
  animation: partner-lane 60s linear infinite;
}

.partners-slider__lane:nth-of-type(even) .partners-slider__item-container {
  animation-direction: reverse;
}

.partners-slider__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 0;
  height: 192px;
  min-width: 256px;
}

@keyframes partner-lane {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%) translateX(-16px);
  }
}
