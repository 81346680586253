@media print {
  @page {
    margin: 1cm;
  }

  body * {
    font: 12pt Georgia, 'Times New Roman', Times, serif !important;
    line-height: 1.2 !important;
    background: #fff !important;
    color: #000 !important;
    text-align: left !important;
    position: relative !important;
    display: block !important;
    width: 100% !important;
    min-height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    opacity: 1 !important;
  }

  h1 {
    font-size: 24pt !important;
    margin-bottom: 12px !important;
  }

  h2 {
    font-size: 18pt !important;
    margin: 24pt 0 8pt !important;
  }

  h3,
  h4,
  h5,
  h6 {
    font-size: 15pt !important;
    margin: 10pt 0 8pt !important;
  }

  p {
    margin: 8pt 0 !important;
  }

  li {
    margin: 10pt 0 !important;
    padding-left: 10pt !important;
  }

  .header,
  .footer,
  .home-banner__logo,
  .home-banner__media-container,
  .introduction__cta,
  .simple-slider__footer,
  .programs__item-cta,
  .partners-slider,
  .contact,
  #modal,
  .article-showcase__cta,
  script,
  .result__cta,
  .articles__bar,
  .article-featured__cta,
  .article-slider__footer,
  .community__map,
  .community__search,
  .toggle-select,
  .pager,
  .page-title__back,
  .cases-showcase__nav,
  .cases-showcase__cta,
  .simple-slider__nav,
  .swiper-pagination,
  .quote-slider__cta,
  .promo__header,
  .promo__video,
  .promo__info .icon,
  .promo__cta,
  .promo__background {
    display: none !important;
  }

  .wrap {
    max-width: none;
    padding: 0;
  }

  a,
  blockquote,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  img,
  table,
  pre,
  ul,
  ol {
    page-break-inside: avoid;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  img {
    page-break-after: avoid;
  }

  img {
    max-width: 5cm !important;
    max-height: 5cm !important;
    margin: 16pt 0 !important;
    object-fit: cover !important;
  }

  .article__header-authors a {
    display: inline-block !important;
    width: auto !important;
  }

  .article__body li {
    display: list-item !important;
  }

  .home-banner__title {
    bottom: auto !important;
  }
}
