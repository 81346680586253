.alert-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 100;
}

.alert {
  width: calc(100 * var(--vw, 1vw) - 40px);
  max-width: 400px;
  animation: fadeIn 0.5s ease, fadeOut 0.5s ease 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
