.btn {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 14px 24px;
  font-size: 1.8rem;
  line-height: 1.5;
  letter-spacing: -0.05em;
  font-weight: 600;
  border-radius: 2px;
  border: none;
  outline-offset: 3px;
  font-family: 'Albert', sans-serif;
  transition: var(--transition);
}

.btn--big {
  font-size: 3.2rem;
  padding: 19px;
}

.btn .loader {
  margin-left: 2rem;
  font-size: 0.3rem;
}

.btn--primary {
  color: white;
  background: var(--primary);
}

.btn--primary:hover,
.btn--primary:focus-visible {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--primary);
}

.btn--secondary {
  color: white;
  background-color: var(--secondary);
}

.btn--secondary:hover,
.btn--secondary:focus-visible {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--secondary);
}

.btn--dark {
  color: white;
  background-color: var(--text);
}

.btn--dark:hover,
.btn--dark:focus-visible {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--text);
}

.btn--light {
  color: var(--text);
  background-color: white;
}

.btn--light:hover,
.btn--light:focus-visible {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), white;
}

.btn--transparent {
  color: var(--primary);
  background-color: transparent;
}

.btn--transparent:hover,
.btn--transparent:focus-visible {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), transparent;
}

.btn[disabled] {
  background-color: grey;
}

.btn svg {
  transition: var(--transition);
}

.link {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 13px 0;
  font-size: 1.8rem;
  line-height: 1.5;
  font-family: 'Albert', sans-serif;
  font-weight: 600;
  border-color: transparent;
  border-width: 2px 0;
  border-style: solid;
  outline-offset: 2px;
}

.link--primary {
  color: var(--primary);
}

.link--primary:hover {
  border-bottom-color: var(--primary);
}

.link--secondary {
  color: var(--secondary);
}

.link--secondary:hover {
  border-bottom-color: var(--secondary);
}

.link--light {
  color: white;
}

.link--light:hover {
  border-bottom-color: white;
}

.link--dark {
  color: var(--text);
}

.link--dark:hover {
  border-bottom-color: var(--text);
}

.slider__nav {
  display: flex;
  gap: var(--spacing-3);
}

.slider__arrow {
  padding: 17px;
  border: 1px solid currentColor;
  border-radius: 2px;
  outline-offset: -4px;
  transition: var(--transition);
}

.slider__arrow:focus-visible,
.slider__arrow:not([disabled]):hover {
  border-color: var(--text);
  background-color: var(--text);
  color: white;
}

.slider__arrow[disabled] {
  opacity: 0.5;
}

.slider__arrow--prev {
  transform: rotate(90deg);
}

.slider__arrow--next {
  transform: rotate(-90deg);
}

@media only screen and (max-width: 899px) {
  .btn {
    font-size: 1.8rem;
    padding: 15px 24px;
  }

  .slider__nav {
    gap: var(--spacing-2);
  }
}
