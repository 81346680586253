.article-showcase__container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  z-index: 0;
}

.flip .article-showcase__container {
  flex-direction: row-reverse;
}

.article-showcase__title,
.article-showcase__desc {
  text-align: center;
}

.article-showcase__desc {
  max-width: 720px;
}

.article-showcase__cta {
  margin: 0 auto;
}

.article-showcase__item-container {
  flex: 0 0 calc((100% - 80px) * 0.5);
}

.article-showcase__main {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 0 0 calc((100% - 80px) * 0.5);
  min-height: 608px;
  background-color: rgba(35, 59, 75, 0.4);
  color: white;
}

.article-showcase__main--height {
  min-height: 550px;
}

.article-showcase__main:hover .article-showcase__item-title,
.article-showcase__main:focus-visible .article-showcase__item-title,
.article-showcase__item:hover .article-showcase__item-title,
.article-showcase__item:focus-visible .article-showcase__item-title {
  text-decoration: underline;
}

.article-showcase__main img {
  z-index: -1;
}

.article-showcase__item {
  position: relative;
  display: flex;
  align-items: center;
}

.article-showcase__item-img {
  position: relative;
  flex: 0 0 38.5%;
  padding-top: 38.5%;
}

.article-showcase__item-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.article-showcase .article-showcase__slide {
  height: auto;
}

.article-showcase__slide.block-loader {
  height: 250px;
}

.article-showcase__main.block-loader {
  background-color: var(--surface100);
}

@media only screen and (max-width: 1279px) {
  .article-showcase__container {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .flip .article-showcase__container {
    flex-direction: column-reverse;
  }

  .article-showcase__item-container {
    flex-basis: auto;
  }
}

@media only screen and (max-width: 767px) {
  .article-showcase__container {
    flex-direction: column-reverse;
  }

  .article-showcase__main {
    min-height: 436px;
    width: 100%;
  }

  .article-showcase__slide {
    width: 256px;
  }

  .article-showcase__item {
    flex-direction: column;
    width: 100%;
  }

  .article-showcase__slider {
    width: calc(100% + 48px);
    margin: 0 -24px;
    padding: 0 24px;
  }

  .article-showcase__item-img {
    width: 100%;
    padding-top: 100%;
  }
}
