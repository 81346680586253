.quote-slider__title {
  text-align: center;
  color: var(--secondary);
}

.quote-slider__slider {
  width: 100%;
}

.quote-slider__slide {
  display: flex;
  height: auto;
  background-color: var(--surface100);
}

.quote-slider__img-container {
  position: relative;
  flex: 0 0 50%;
  padding-top: 50%;
}

.quote-slider__content {
  align-items: flex-start;
  justify-content: center;
  flex: 0 0 50%;
}

.quote-slider__quote {
  text-align: center;
  margin: auto;
}

.quote-slider__cta {
  margin-left: auto;
}

.quote-slider .swiper-pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  position: relative;
  bottom: auto;
  margin: 60px 0 0;
}

.quote-slider .swiper-pagination .swiper-pagination-bullet {
  display: block;
  width: 16px;
  height: 16px;
  margin: 0;
  opacity: 1;
  background-color: var(--surface200);
}

.quote-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--primary);
}

.quote-slider .swiper-pagination .swiper-pagination-bullet:focus-visible {
  outline: 2px solid var(--text);
}

@media only screen and (max-width: 1279px) {
  .quote-slider__slide {
    flex-direction: column;
  }

  .quote-slider__img-container {
    flex: 0 0 auto;
    padding-top: min(100%, 600px);
  }

  .quote-slider__content {
    flex: 1 0 auto;
    height: 575px;
  }

  .quote-slider .swiper-pagination {
    gap: 16px;
  }

  .quote-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .quote-slider__content {
    height: auto;
  }

  .quote-slider .swiper-pagination {
    margin-top: 44px;
  }
}
