.community {
  min-height: calc(var(--vh, 1vh) * 100);
  padding: var(--spacing-20) 0 var(--spacing-10);
}

.community__container {
  display: flex;
}

.community__lister {
  flex: 1 1 50%;
  width: 0;
}

.community__search {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.community__filters {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
}

.community__filters .input {
  width: calc(50% - 4px);
  margin-bottom: 0;
}

.community__title-highlight {
  color: var(--secondary);
}

.community__search-input {
  margin: 0;
  width: 240px;
}

.community__filter-btn {
  margin-left: auto;
}

.community__map {
  flex: 1 1 50%;
  width: 0;
  align-self: flex-start;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: calc(var(--header-height) + 75px);
}

.community__list {
  display: flex;
  flex-wrap: wrap;
}

.community .toggle-select {
  display: none;
  margin: 0;
}

.community__lister-list {
  min-height: calc(var(--vh, 1vh) * 100);
}

@media only screen and (max-width: 1279px) {
  .community {
    padding: var(--spacing-15) 0 var(--spacing-10);
  }

  .community__map {
    top: calc(var(--header-height) + 35px);
  }
}

@media only screen and (max-width: 999px) {
  .community__container {
    flex-direction: column;
  }

  .community__lister,
  .community__map {
    flex: 0 0 auto;
    width: 100%;
  }

  .community__search-input {
    max-width: calc(100% - 92px);
  }

  .community .toggle-select {
    display: flex;
    align-self: flex-start;
    width: 100%;
  }

  .community .toggle-select__container {
    flex-direction: row;
  }

  .community .toggle-select__item {
    flex: 1 1 auto;
  }

  .community .hide {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .community {
    padding: var(--spacing-10) 0 var(--spacing-5);
  }
}
