.programs__header {
  text-align: center;
}

.programs__desc {
  max-width: 720px;
}

.programs__container {
  display: flex;
  flex-wrap: wrap;
}

.programs__item {
  flex: 1 0 calc((100% - 80px) / 2);
  width: 0;
  min-height: 716px;
  background-color: white;
}

.programs__item-container {
  height: 100%;
}

.programs__item-content {
  flex-grow: 1;
}

.programs__item-title u {
  text-decoration-color: var(--secondary);
}

.programs__item-desc span {
  color: var(--secondary);
}

.programs__item-footer {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1279px) {
  .programs__item {
    flex: 1 0 100%;
    min-height: unset;
  }
}
