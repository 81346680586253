.article-front.article-showcase {
  margin-top: 80px;
}

.article-front__placeholder {
  height: 120vh;
}

@media only screen and (max-width: 1279px) {
  .article-front.article-showcase {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .article-front.article-showcase {
    margin-top: 40px;
  }
}
