.landing-footer {
  margin-top: auto;
  font-weight: 600;
}

.landing-footer .wrap {
  padding: 0;
}

.landing-footer__container {
  display: flex;
  flex-wrap: wrap;
}

.landing-footer__subcontainer {
  display: flex;
  width: 50%;
}

.landing-footer__col {
  position: relative;
  width: calc(100% / 4);
  padding: 64px 3.7%;
  min-height: 150px;
  flex-grow: 1;
}

.landing-footer__subcontainer .landing-footer__col {
  width: 50%;
  padding: 64px 7.4%;
}

.landing-footer__col--red {
  background-color: var(--secondary);
  color: white;
}

.landing-footer__col--grey {
  background-color: var(--surface100);
}

.landing-footer__col--white {
  background-color: white;
  color: var(--text);
}

.landing-footer__col--dark-blue {
  background-color: var(--text);
  color: white;
}

.landing-footer__col-title {
  margin-bottom: 32px;
}

.landing-footer__logo {
  height: auto;
  width: 68.5%;
}

.landing-footer a,
.landing-footer__address {
  text-decoration: underline;
  line-height: 1.5;
}

.landing-tooter__corp {
  max-width: 100%;
}

@media only screen and (max-width: 899px) {
  .landing-footer__subcontainer {
    flex-direction: column;
    width: 50%;
    order: -1;
  }

  .landing-footer__col:first-of-type {
    width: 100%;
    order: -2;
  }

  .landing-footer__col {
    width: 50%;
    padding: 80px 24px;
  }

  .landing-footer__subcontainer .landing-footer__col {
    width: 100%;
  }
}
