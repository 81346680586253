.landing-contact .wrap {
  padding: 0;
}

.landing-contact__container {
  display: flex;
}

.landing-contact__header,
.landing-contact__content {
  padding: 128px 4.2%;
  flex: 0 0 50%;
  width: 0;
}

.landing-contact__header {
  display: flex;
  flex-direction: column;
}

.landing-contact__header-bottom {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin: auto -8.4% -128px auto;
}

.landing-contact__title {
  margin-bottom: 32px;
}

.landing-contact__info {
  margin-bottom: 20px;
  text-align: right;
}

.landing-contact__img {
  max-width: 346px;
}

.landing-contact__content {
  background-color: var(--primary);
  color: white;
}

.landing-contact__desc {
  line-height: 1.5;
}

.landing-contact__cta {
  margin-top: 64px;
}

@media only screen and (max-width: 899px) {
  .landing-contact__container {
    flex-direction: column;
  }

  .landing-contact__header,
  .landing-contact__content {
    width: 100%;
  }

  .landing-contact__header {
    flex-direction: row;
    padding: 48px 24px 20px;
  }

  .landing-contact__header-top {
    display: flex;
    flex-direction: column;
  }

  .landing-contact__header-bottom {
    width: 57.4%;
    max-width: 250px;
    align-self: flex-end;
    margin: -48px -24px -20px auto;
  }

  .landing-contact__title {
    flex: 0 0 50%;
  }

  .landing-contact__info {
    margin: auto 0 0;
    text-align: left;
  }

  .landing-contact__img {
    max-width: 100%;
  }

  .landing-contact__content {
    padding: 80px 24px;
  }

  .landing-contact__cta {
    margin-top: 24px;
  }
}
