.articles {
  padding: var(--spacing-20) 0 0;
}

.articles__header .input {
  margin-bottom: 0;
}

.articles__desc {
  max-width: 1024px;
}

.articles__bar {
  display: flex;
  gap: 16px;
}

.articles__search {
  flex: 0 0 224px;
}

.articles__category {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
}

.articles__category-nav {
  display: flex;
  align-items: flex-start;
  align-self: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  transition: var(--transition);
}

.articles__category-nav.expand {
  height: var(--height);
}

.articles__category-all {
  flex-shrink: 0;
  align-self: flex-end;
  margin-top: -0.5rem;
}

@media only screen and (max-width: 1279px) {
  .articles {
    padding: var(--spacing-15) 0 0;
  }

  .articles__bar {
    flex-direction: column;
    align-items: flex-start;
  }

  .articles__search {
    flex-basis: auto;
  }
}

@media only screen and (max-width: 767px) {
  .articles {
    padding: var(--spacing-10) 0 0;
  }

  .articles__bar {
    flex-direction: row;
    /* align-items: flex-start; */
  }

  .articles__category-select,
  .articles__search {
    flex: 0 0 calc((100% - 16px) / 2);
    width: 0;
  }
}
