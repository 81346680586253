.flex {
  display: flex;
  flex-direction: column;
}

.flex--center {
  align-items: center;
}

.px-xs {
  padding-left: var(--spacing-3);
  padding-right: var(--spacing-3);
}

.px-sm {
  padding-left: var(--spacing-5);
  padding-right: var(--spacing-5);
}

.px-md {
  padding-left: var(--spacing-10);
  padding-right: var(--spacing-10);
}

.px-lg {
  padding-left: var(--spacing-10);
  padding-right: var(--spacing-10);
}

.py-xs {
  padding-top: var(--spacing-3);
  padding-bottom: var(--spacing-3);
}

.py-sm {
  padding-top: var(--spacing-5);
  padding-bottom: var(--spacing-5);
}

.py-md {
  padding-top: var(--spacing-10);
  padding-bottom: var(--spacing-10);
}

.py-lg {
  padding-top: var(--spacing-20);
  padding-bottom: var(--spacing-20);
}

.gap-xs {
  gap: var(--spacing-2);
}

.gap-sm {
  gap: var(--spacing-3);
}

.gap-md {
  gap: var(--spacing-5);
}

.gap-lg {
  gap: var(--spacing-10);
}

@media only screen and (max-width: 1279px) {
  .py-lg {
    padding-top: var(--spacing-15);
    padding-bottom: var(--spacing-15);
  }

  .gap-lg {
    gap: var(--spacing-5);
  }
}

@media only screen and (max-width: 767px) {
  .px-xs {
    padding-left: var(--spacing-2);
    padding-right: var(--spacing-2);
  }

  .px-sm {
    padding-left: var(--spacing-3);
    padding-right: var(--spacing-3);
  }

  .px-md {
    padding-left: var(--spacing-3);
    padding-right: var(--spacing-3);
  }

  .px-lg {
    padding-left: var(--spacing-3);
    padding-right: var(--spacing-3);
  }

  .py-xs {
    padding-top: var(--spacing-2);
    padding-bottom: var(--spacing-2);
  }

  .py-sm {
    padding-top: var(--spacing-3);
    padding-bottom: var(--spacing-3);
  }

  .py-md {
    padding-top: var(--spacing-5);
    padding-bottom: var(--spacing-5);
  }

  .py-lg {
    padding-top: var(--spacing-10);
    padding-bottom: var(--spacing-10);
  }

  .gap-xs {
    gap: var(--spacing-1);
  }

  .gap-sm {
    gap: var(--spacing-2);
  }

  .gap-md {
    gap: var(--spacing-3);
  }
}
