.newsletter-inline {
  position: relative;
}

.newsletter-inline .hs-button {
  display: block;
  width: 100%;
  text-align: center;
  background-color: var(--secondary);
  border-radius: 2px;
}

.newsletter-inline .hs-button:hover,
.newsletter-inline .hs-button:focus-visible {
  border-radius: 2px;
}

.newsletter-inline .submitted-message {
  padding: 0;
  font-size: 2rem;
}
