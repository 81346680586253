.question-field__label {
  display: block;
  margin-bottom: 8px;
}

.question-field__container {
  position: relative;
}

.question-field__option {
  display: block;
  width: 100%;
  padding: 16px;
  text-align: left;
}

.question-field__option:not(:last-of-type) {
  border-bottom: 1px solid var(--surface200);
}

.question-field__option:hover {
  background-color: var(--surface100);
}

.question-field__dropdown {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
  max-height: 320px;
  opacity: 0;
  background: white;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
  visibility: hidden;
  transition: var(--transition);
  transform: translateY(6px);
  list-style: none;
  overflow: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 2;
}

.question-field__dropdown::-webkit-scrollbar {
  display: none;
}

.question-field__dropdown--visible {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.question-field__selected {
  margin-top: 24px;
}

.question-field__selected-question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 12px;
  background-color: white;
  border-radius: 2px;
  border-bottom: 1px solid var(--border300);
}

.question-field__selected-remove {
  color: var(--primary);
  padding: 4px;
}

.question-field__selected-answer {
  padding: 16px;
  margin: 0 8px;
  border-radius: 0 0 2px 2px;
  background-color: white;
}
