.user-edit-form__container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 160px;
}

.user-edit-form {
  flex: 0 0 100%;
}

.user-edit-form__submit {
  justify-content: center;
}

.user-edit-form__checkbox {
  margin: 0;
}

@media only screen and (max-width: 1279px) {
  .user-edit-form {
    margin-top: 24px;
  }

  .user-edit-form__container {
    flex-direction: column;
  }

  .user-edit-form__container .scrollspy {
    order: -1;
  }
}
